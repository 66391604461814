@font-face {
    font-family: 'IvyEpic';
    src: url('../assets/fonts/fonnts.com-IvyEpic_Black.otf') format('otf'),
         url('../assets/fonts/fonnts.com-IvyEpic_Black_Italic.otf') format('otf'),
         url('../assets/fonts/fonnts.com-IvyEpic_Bold.otf') format('otf'),

         url('../assets/fonts/fonnts.com-IvyEpic_Bold_Italic.otf') format('otf'),
         url('../assets/fonts/fonnts.com-IvyEpic_ExtraBold.otf') format('otf'),

         url('../assets/fonts/fonnts.com-IvyEpic_ExtraBold_Italic.otf') format('otf'),
         url('../assets/fonts/fonnts.com-IvyEpic_ExtraLight.otf') format('otf'),

         url('../assets/fonts/fonnts.com-IvyEpic_ExtraLight_Italic.otf') format('otf'),
         url('../assets/fonts/fonnts.com-IvyEpic_Italic.otf') format('otf'),
         url('../assets/fonts/fonnts.com-IvyEpic_Light.otf') format('otf'),
         url('../assets/fonts/fonnts.com-IvyEpic_Light_Italic.otf') format('otf'),
         url('../assets/fonts/fonnts.com-IvyEpic_Medium.otf') format('otf'),
         url('../assets/fonts/fonnts.com-IvyEpic_Medium_Italic.otf') format('otf'),
         url('../assets/fonts/fonnts.com-IvyEpic_Regular.otf') format('otf'),
         url('../assets/fonts/fonnts.com-IvyEpic_SemiBold.otf') format('otf'),
         url('../assets/fonts/fonnts.com-IvyEpic_SemiBold_Italic.otf') format('otf'),
     
         url('../assets/fonts/fonnts.com-IvyEpic_Thin_Italic.otf') format('otf'),
         url('../assets/fonts/fonnts.com-IvyEpic_Variable_Thin.otf') format('otf');
    font-weight: normal;
    font-style: normal;
}

.form-registro-abordo-volante{
    font-family: 'IvyEpic', sans-serif;
    width: 100%;
    min-height: 1000px;
    /*
    height: calc(100vh - 115px);
    max-height: 1100px;*/
    background: #457bd7;
    background-image: url('../assets/images/promociones/promo-abordo-background.webp');
    padding: 6rem 0rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    align-content: center;
    position: relative;
}
.form-abordo{
    width: 100%;
    display: flex;
    justify-content: end;
}
.form-abordo-box{
    width: 49%;
}
.form-abordo-box input{
    width: 50%;
}
.formulario-abordo{
    width: 50%;
    justify-content: end;
    display: flex;
}
.formulario-abordo-title h2{
    color: #FFC21D;
}
.formulario-abordo-title p{
    color: #ffffff;
}
.formulario-abordo-box{
    width: 100%;
    height: fit-content;
    min-width: 500px;
    max-width: 700px;
    border-radius: 22px;
    padding: 4rem 2rem;
    background: #002F55;
    -webkit-box-shadow: 7px 7px 8px 0px rgba(0,0,0,0.33);
    -moz-box-shadow: 7px 7px 8px 0px rgba(0,0,0,0.33);
    box-shadow: 7px 7px 8px 0px rgba(0,0,0,0.33);
}
.formulario-abordo-box-registro{
    width: 100%;
    height: fit-content;
    max-width: 650px;
    border-radius: 22px;
    padding: 4rem 2rem;
    background: #002F55;
    -webkit-box-shadow: 7px 7px 8px 0px rgba(0,0,0,0.33);
    -moz-box-shadow: 7px 7px 8px 0px rgba(0,0,0,0.33);
    box-shadow: 7px 7px 8px 0px rgba(0,0,0,0.33);
}
.form-abordo-group{
    margin: 1rem 0rem;
}
.form-abordo-group-registro{
    margin: 1rem 0rem;
    display: flex;
    justify-content: space-between;
    color: #fff;
}
.form-abordo-group-registro input[type='text'],
.form-abordo-group-registro input[type='email'],
.form-abordo-group-registro input[type='tel'],
.form-abordo-group-registro input[type='number'],
.form-abordo-group-registro input[type='date'],
.form-abordo-group-registro input[type='password']{
    width: 100%;
    padding: .5rem 1rem;
    border-radius: 6px;
    background: #5d879e;
    border: none;   
    color: #fff; 
}
.form-abordo-group-registro input[type='file']{
    width: 100%;
    padding: .5rem 1rem;
    border-radius: 6px;
    background: transparent;
    border: 2px solid #5d879e;   
}
.form-abordo-group-registro P{
    margin-bottom: .2rem;   
}
.chackbox_text.registro{
    color: #fff;
}
.form-abordo-group input{
    width: 100%;
    padding: .5rem 1rem;
    border-radius: 6px;
    background: #5d879e;
    border: none;    
}
.form-abordo-group p{
    color: #fff;
    margin-bottom: 0rem;
}
.formulario-abordo-box >.chackbox_text{
    color: #fff;    
}
.formulario-abordo-box >.chackbox_text label{
    color: #5e92ec;
    cursor: pointer;
}
.btn_enviar_formulario_abordo {
    justify-content: center;
    display: flex;
}
.btn_enviar_formulario_abordo button {
    border-radius: 3px;
    margin: 2rem;
    cursor: pointer;
    background: #FFAF00;
    text-decoration: none;
    color: #002F55;
    padding: .5rem 3rem;
    border: none;
    font-size: 18px;
    display: flex;
    font-weight: 700;
    letter-spacing: -1px;
}
.form-abordo-group input::placeholder {
    font-size: 14px;
}
.form-abordo-registro-option label{
    color: #fff;
    display: flex;
}

.form-abordo-registro-option {
    margin-top: 1rem;
    justify-content: center;
    display: flex;
}
.form-abordo-registro-option p{
    color: #fff;
    display: flex;
}
.form-abordo-registro-option label{
    color: #5e92ec;
    margin-left: 4px;
    cursor: pointer;
}
.formulario-abordo-imgs{
    width: 50%;
}
.formulario-abordo-imgs img{
    width: 100%;
}
.message_error{
    background: #F8D7DA;
    width: 100%;
    color: #842029;
    border-radius: 3px;
    padding: .5rem .4rem;
    font-size: 14px;
}   

.head-line-promo{
    width: 100%;
    display: flex;
    justify-content: space-between;
    top: 0;
    position: absolute;
}
.numeral-promo{
    background: #FFC21D;
    color: #333;
    align-items: center;
    display: flex;
    padding: .5rem 1rem;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}
.numeral-promo label{
    font-weight: 600;
}
.numeral-promo label:nth-child(1){
    letter-spacing: -1px;
    line-height: 20px;
    font-weight: 600;
    font-size: 1.2rem;
    text-align: center;
    margin-right: 1rem;
}
.head-line-promo-content{
    width: 100%;
    display: flex;
    justify-content: space-between;
}    

.head-line-promo-box img{
    width: 50%;
    max-width: 500px;
    min-width: 415px;
    margin-top: 2rem;
}


/* input file aspecto */
.file-select {
    position: relative;
    display: inline-block;
  }
  
  .file-select::before {
    background: transparent;
    color: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dotted #5d879e;
    border-radius: 3px;
    content: 'Seleccionar'; /* testo por defecto */
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  
  .file-select input[type="file"] {
    opacity: 0;
    width: 200px;
    height: 32px;
    display: inline-block;
  }
  
  #foto_pase::before {
    content: 'Sube tu archivo';
    color: #ddd;
  }
 
  #foto_unidad::before {
    content: 'Sube tu archivo';
    color: #ddd;
  }

  /* */

  .form_input_img {
    display: grid;
    margin-top: 1rem;
}

.form_input_img .file-style {
    /* font-family: 'Trebuchet MS'; */
    width: 100%;
    display: grid;
    margin: auto;
    cursor: pointer;
    text-align: center;
    padding: 1.5rem;
    border-radius: 5px;
    border: 2px dashed #516577;
    position: relative;
}

label.file-style.abordo.upload {
    border: 2px solid #7ED704;
}

.form-abordo-group-registro input[type='file'] {
    width: 100%;
    padding: .5rem 1rem;
    border-radius: 6px;
    background: transparent;
    border: 2px solid #5d879e;
}
.form_input_img input[type=file] {
    /* display: none; */
    height: 100%;
    width: 100%;
    position: absolute;
    cursor: pointer;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
}

.form_input_img i {
    font-size: 2rem;
    color: #028BFD;
    cursor: pointer;
}

.form-abordo-group-registro P {
    margin-bottom: .2rem;
    font-size: 15px;
}
.form_input_img p {
    color: #516577;
}

.file-style.abordo:hover {
    background-color: #a9c4d3;
}
i.fi.fi-rs-check-circle {
    color:  #7ED704;
}
.barra_menu_promo{
    width: 100%;
    height: 106px;
    background: #fff;
    background: linear-gradient(71.93deg, #001b33 26.34%, #004887 56.72%, #273b49 94.79%);
}
.box-confirmacion-abordo p{
    justify-content: center;
    color: #ffff;
    margin: .5rem 0rem;
}
.box-confirmacion-abordo-icon i{
    color: #FEC224!important;
    justify-content: center;
    display: flex;
    font-size: 4rem;
    margin-bottom: 2rem;
}
.box-confirmacion-abordo-box{
    justify-content: center;
    display: flex;
    text-align: center;
    margin: .5rem;
}
.box-confirmacion-abordo-box:nth-child(3){
    font-weight: 600;
    border: 3px solid #0277D0;
}
.box-confirmacion-abordo-box label{
    color: #FEC224;
}

.form_input_img label{
    font-size: 15px;
    margin-bottom: .3rem;
}

@media (max-width: 768px){
    .form-registro-abordo-volante {
        padding: 2rem 0rem;
    }
    .form-abordo {
        width: 100%;
        display: block;        
    }
    .formulario-abordo-imgs {
        width: 100%;
    }
    .formulario-abordo {
        width: 100%;
    }
    .head-line-promo-content {
        width: 100%;
        display: block;   
    }
    .head-line-promo {
        width: 100%;
        display: flex;
        justify-content: space-between;
        top: 0;
        position: relative;
    }
    .numeral-promo {
        justify-content: center;
        margin-bottom: 2rem;
    }

}