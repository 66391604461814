.banner-header-promo{
    background-color: #10162e;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: -3rem;
}
.banner-header-promo img{
    width: 100%;
}
.sectionPromo_content {
    /*background: linear-gradient(to right, #fff 0%, #fff 50%, #CECECE 50%, #CECECE 100%);*/
    padding: 4rem 0rem;
}
.box_promo{
    display: inline-flex;
    width: 100%;
}
.content_text_promo{
    width: 70%;
} 
.content_contador_promo{
    width: 30%;
    justify-content: center;
    display: flex;
    max-width: 380px;
}
.content_contador_promo{
    background-color: #EBEBEB;
}
.content_contador_promo .content_promo{
    float: right;
}
.content_promo{
    width: 90%;
    display: grid;
}
.content_promo ul{
    padding: 0;
    margin: 0;
}
.content_promo ul strong{
    font-weight: 500;
}
.content_promo h3{
    /*margin-top: 4rem;*/    
    font-size: 22px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 16px;
    font-weight: 400;
    
}
.content_promo h4{
    font-size: 22px;
    letter-spacing: 0;
    line-height: 40px;
    margin-top: 1rem;
    margin-bottom: .5rem;
    font-weight: 400;
}
.content_promo label{
    font-size: 20px;
}

.content_promo p{
    /*margin-top: 1rem;*/
    font-size: 18px;
}

.cta_promo{
    margin-top: 2rem;
    padding: 10px 20px;
    border-radius: 5px;
    background: #225981;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    border: 1px solid #225981;
    text-decoration: none;
    justify-content: center;
    display: flex;
    align-items: center;
    margin: .2rem;
}
.cta_promo i{
    margin-right: 5px;
}
.content_promo a:hover{
    background-color: #F5A800;
    border: 1px solid #FF8F00;
}
.condiciones-promo{
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.condiciones-promo ul{
    margin-left: 0rem;
    padding-left: 0rem;
}
.content_promo.termino-condiciones h4{
    font-size: 18px;
}
.content_promo.termino-condiciones ul{
    padding-left: 0rem;
}
.content_promo.termino-condiciones ul li{
    font-size: 16px;
    margin-bottom: 8px;
}
.content-isotipo{
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}
.content-isotipo img{
    margin-top: 4rem;
    margin-bottom: 2rem;
    width: 35%;
}
.lateral-promo{
    margin-bottom: 1rem;
}

.lateral-promo a{
    margin-bottom: 1rem;
}
.lateral-promo img{
    width: 100%;
}
.content_promo_btn{
    display: flex;
    margin-top: 2rem;
}
.content_promo_btn a:nth-child(1){
    /*width: 50%;
    margin-right: 1rem;*/
}
.content_promo_btn a:nth-child(2){
    background: #fff;
    color: #225981;
    font-size: 18px;
    font-weight: 500;
    border: 2px solid #225981;
}
/* Temporizador */
.temporizadr{
    display: inline-flex;
    width: 100%;
    margin-top: 3rem;
}
.item-time{
    display: grid;
    width: 20%;
    justify-content: center;
}
.item-time span{
    font-size: 3em;
    color: #6D6D6D;
    justify-content: center;
    display: flex;
}
.item-time label{
    font-size: 18px;
    color: #A0A0A0;
    font-weight: 400;
}
.colon{
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 74px;
    color: #A0A0A0;
}

.btn-ganadores a{
    margin-top: 1rem;
    padding: 10px 10px;
    border-radius: 5px;
    background: #225981;
    color: #404040;
    font-size: 18px;
    font-weight: 500;
    border: 1px solid #225981;
    text-decoration: none;
    justify-content: center;
    background-color: #fff;
    border: 2px solid #404040;
}
.btn-ganadores a:hover{
    background-color: #F5A800;
    border: 1px solid #FF8F00;
    color: #fff;
}
@media (max-width: 768px){
    .sectionPromo_content{
        background: #f5f5f5;
    }
    .box_promo {
        display: block;
        width: 100%;
    }
    .content_promo {
        width: 100%;
    }
    .content_text_promo, .content_contador_promo{
        width: 100%;
    }
    .slide-subtext {
        position: absolute;
        top: 40%;
        right: 8%;
        font-size: 12px;
        font-weight: 400;
        color: #fff
    }    
    .slide-title {
        /*
        top: 8%;
        left: 8%;
        font-size: 1rem;     
        */ 
    }
    .item-time label{
        font-size: 16px;
        color: #A0A0A0;
        font-weight: 400;
    }
    .promo_container {
        display: block!important;
    }
}